import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'
import DynamicZone from '../components/DynamicZone/DynamicZone'
import Img from 'gatsby-image'

const serviceTemplate = ( { data: { strapi: { service } }, location } ) => {

	return (
		<>
			<SEO title={ `${ service.title } | Services` } description={ service.seoDescription } pathname={ location.pathname } />

			<div className="article wrapper">
				<div className="article__wrapper">

					<Img className="article__image" fluid={ service.image.imageFile.childImageSharp.fluid } />

					<h1 className="article__header">{ service.title }</h1>

					<div className="article__body-content">
						{ service && service.content &&
							<DynamicZone components={ service.content } />
						}
					</div>

				</div>
			</div>
		</>
	)
}

export default serviceTemplate

export const query = graphql`
	query serviceQuery($id: ID!) {
		strapi {
			service (id: $id) {
				id
				seoDescription
				title
				image {
					url
					imageFile {
						childImageSharp {
							fluid(maxWidth: 1000) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
				}
				content {
					... on StrapiQuery_ComponentArticleTextBlock {
						id
						text
					}
					... on StrapiQuery_ComponentArticleImage {
						id
						wideImage
						title
						image {
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 1000) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentArticleQuote {
						id
						author
						quote
					}
					... on StrapiQuery_ComponentCommonSectionLink {
						id
						linkTitle
						linkUrl
					}
					... on StrapiQuery_ComponentArticleVideo {
						id
						videoTitle
						videoNumber
					}
				}
			}
		}
	}
`